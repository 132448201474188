<template>
  <el-dialog
    title="添加兑奖单"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container" style="min-height: 600px">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="购货方:" prop="cust_name">
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            style="width: 200px"
            @select-val-alldata="clientSelect"
          ></client-search>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input
            v-model="form.boss"
            style="width: 200px"
            disabled
          ></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="电话:" prop="mobile">
          <el-input
            v-model="form.mobile"
            style="width: 200px"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="地址:" prop="address">
          <el-input
            v-model="form.address"
            disabled
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="出货仓库" prop="">
          <el-select
            v-model="form.depot_id"
            style="width: 200px"
            filterable
            clearable
            placeholder="选择出货仓库"
          >
            <el-option
              v-for="item in depotList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="送货人" prop="">
          <el-select
            v-model="form.driver_id"
            clearable
            style="width: 200px"
            placeholder="请选择送货人"
          >
            <el-option
              v-for="(i, idx) in driverSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="业务员" prop="">
          <el-select
            v-model="form.staff_id"
            style="width: 200px"
            filterable
            clearable
            placeholder="选择业务员"
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item label="添加兑奖介质" prop="">
          <el-button
            v-for="(btn, index) in btnList"
            :key="index"
            @click="handleBtnClick(btn)"
          >
            {{ btn.goods_name }}
          </el-button>
        </el-form-item>
      </el-form>
      <div
        v-for="(table, index) in tableList"
        :key="index"
        v-loading="loading"
        style="margin-top: 10px"
      >
        <el-card>
          <el-button
            style="margin-bottom: 10px"
            type="primary"
            @click="hanleAddJiangpin(index)"
          >
            添加奖品
          </el-button>
          <el-table :data="table.goods_jiezhi">
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            >
              <template #default="{ row }">
                <div v-if="item.prop == 'duijiang'">回收</div>
                <div v-else-if="item.prop == 'unit'">
                  {{ row.goods_unit }}
                </div>
                <div v-else-if="item.prop == 'goods_num'">
                  <el-input-number
                    v-model.number="row.goods_num"
                    type="number"
                  ></el-input-number>
                </div>
                <div v-else>
                  {{ row[item.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <el-button type="text" @click="handleDeleteHuishou(index)">
                删除
              </el-button>
            </el-table-column>
          </el-table>
          <el-table :show-header="false" :data="table.goods_prize">
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            >
              <template #default="{ row, $index }">
                <div v-if="item.prop == 'duijiang'">奖品</div>
                <div v-else-if="item.prop == 'goods_name'">
                  <goods-search
                    :f-key="row.goods_name"
                    @add-rows="addRows($event, row, index, $index)"
                    @select-goods-all="selectGoods($event, row, index)"
                  ></goods-search>
                </div>
                <div v-else-if="item.prop == 'unit'">
                  <el-select
                    v-model="row.unit_id"
                    clearable
                    style="width: 140px"
                    placeholder="单位"
                  >
                    <el-option
                      v-for="(i, idx) in row.unit_arr"
                      :key="idx"
                      :value="i.id"
                      :label="i.unit_name"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="item.prop == 'goods_num'">
                  <el-input-number
                    v-model.number="row.goods_num"
                    type="number"
                  ></el-input-number>
                </div>
                <div v-else>
                  {{ row[item.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="{ $index }">
                <el-button
                  type="text"
                  @click="handleDeleteJiangpin($index, index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <div style="display: flex; align-items: center; width: 49%">
              <span style="margin-right: 5px">奖现金:</span>
              <el-input
                v-model="table.cash_info.give_money"
                style="width: 200px"
              ></el-input>
            </div>
            <div style="display: flex; align-items: center; width: 49%">
              <span style="margin-right: 5px">奖值合计:</span>
              <el-input
                v-model="table.cash_info.goods_amount"
                disabled
                style="width: 200px"
              ></el-input>
            </div>
          </div>
        </el-card>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 5px">备注:</span>
        <el-input
          v-model="remark"
          type="textarea"
          style="width: 400px"
        ></el-input>
      </div>
    </div>
    <div class="dialog-bottom">
      <el-button type="primary" @click="handleSubmit">保存</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getAllSelect } from '@/api/advanceOrder'
  import { getStaffList } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { driverList } from '@/api/advanceOrder'

  export default {
    name: 'DuiJiang',
    components: { ClientSearch, GoodsSearch },
    props: {
      saleType: {
        type: Number,
        default: 4, //默认仓库直销订单类型 2 访销  4仓库直销
      },
    },
    data() {
      return {
        remark: '',
        form: {
          boss: '',
          mobile: '',
          address: '',
          cust_id: '',
          driver_id: '',
        },
        showDialog: false,
        depotList: [],
        staffList: [],
        tableList: [],
        driverSelect: [],
        btnList: [],
        columns: [
          {
            order: 1,
            label: '兑奖',
            prop: 'duijiang',
            width: '',
          },
          {
            order: 2,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '单位',
            prop: 'unit',
            width: '',
          },
          {
            order: 4,
            label: '数量',
            prop: 'goods_num',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.getBtnList()
        } else {
          this.clearDialog()
        }
      },
    },
    created() {},
    mounted() {
      this.initSelect()
    },
    methods: {
      initSelect() {
        // 出货仓库下拉
        getAllSelect().then((res) => {
          if (res.code == 200) {
            this.depotList = res.data.depot
          }
        })
        // 送货人下拉
        driverList().then((res) => {
          if (res.code == 200) {
            this.driverSelect = res.data
          }
        })
        // 获取业务员下拉
        getStaffList().then((res) => {
          if (res.code == 200) this.staffList = res.data
        })
      },
      async getBtnList() {
        let { data } = await postAction('/promoteAdmin/prize/jiezhi-list')
        this.btnList = data
      },
      clientSelect(val) {
        if (val) {
          this.form.cust_id = val.id
          this.form.boss = val.boss
          this.form.mobile = val.mobile
          this.form.address = val.address
          this.form.account_type = Number(val.pay_way)
        }
      },
      clearDialog() {
        this.tableList = []
        this.$refs.clientSearch.kw = ''
        this.form.cust_id = ''
        this.form = {
          boss: '',
          mobile: '',
          address: '',
        }
      },
      handleBtnClick(btn) {
        console.log(btn)
        let diffId = this.tableList.find(
          (item) => item.cash_info?.cash_id == btn.cash_id
        )
        if (!diffId) {
          try {
            this.loading = true
            postAction('/promoteAdmin/prize/get-detail', {
              cash_id: btn.cash_id,
            }).then((r) => {
              console.log('push')
              this.tableList.push(r.data)
              this.loading = false
            })
          } catch (err) {
            this.loading = false
          }
        }
      },
      handleDeleteHuishou(index) {
        this.tableList.splice(index, 1)
      },
      handleDeleteJiangpin(index, index2) {
        console.log(index, index2)
        this.tableList[index2].goods_prize.splice(index, 1)
      },
      hanleAddJiangpin(index) {
        const cash_id = this.tableList[index].cash_info?.cash_id
        this.tableList[index].goods_prize.push({
          goods_id: '',
          goods_name: '',
          goods_spec: '',
          unit_id: '',
          goods_unit: '',
          goods_num: '',
          goods_price: '',
          cash_id,
        })
      },
      selectGoods(val, row, index) {
        if (val.goods_id != -1) {
          console.log('select', val)
          // Object.assign(row, val)
          this.$set(row, 'goods_id', val.goods_id)
          this.$set(row, 'goods_name', val.goods_name)
          this.$set(row, 'unit_id', val.unit_id)
          this.$set(row, 'goods_unit', val.goods_unit)
          this.$set(row, 'goods_num', val.goods_num)
          this.$set(row, 'goods_price', val.goods_price)
          this.$set(row, 'unit_arr', val.arr_unit)

          row.unit_arr.forEach((i) => {
            i.id = Number(i.id)
          })
          row.unit_id = row.unit_id && Number(row.unit_id)
          // this.$set(row, 'unit_arr', row.arr_unit)
        }
      },
      addRows(val, row, index, $index) {
        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
                item.goods_price = unit.sell_price
              }
            })
            if (item.unit_id == '') {
              item.arr_unit.forEach((unit) => {
                if (unit.unit_type == 1) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                }
              })
            }
            this.$set(item, 'unit_arr', item.arr_unit)
            this.tableList[index].goods_prize.splice($index, 0, item)
          })
          this.$message.success('添加成功')
        }
      },
      handleSubmit() {
        // /saleAdmin/order-pre/do-bill-order-cash
        const cash_ids = []
        const goods_jiezhi = []
        const goods_prize = []
        this.tableList.forEach((i) => {
          cash_ids.push(i.cash_info)
          goods_jiezhi.push(i.goods_jiezhi[0])
          i.goods_prize.forEach((goods) => {
            goods_prize.push(goods)
          })
        })
        let data = {
          remark: this.remark,
          ...this.form,
          sale_type: this.saleType,
          cash_ids: JSON.stringify(cash_ids),
          goods_jiezhi: JSON.stringify(goods_jiezhi),
          goods_prize: JSON.stringify(goods_prize),
        }
        if (this.saleType == 2) {
          data.driver_id = this.form.driver_id
        }
        console.log('data', data)
        postAction('/saleAdmin/order-pre/do-bill-order-cash', data).then(
          (res) => {
            this.$emit('refresh')
            this.$message.success('添加成功')
            this.showDialog = false
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
