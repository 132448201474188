import request from '@/utils/request'

export function getTableList(data) {
  return request({
    url: '/baseAdmin/depot-info/index',
    method: 'post',
    data,
  })
}

// 删除
export function deleteRow(data) {
  return request({
    url: '/baseAdmin/depot-info/delete',
    method: 'post',
    data,
  })
}
// 后台仓库详情
export function getDetail(data) {
  return request({
    url: '/baseAdmin/depot-info/detail',
    method: 'post',
    data,
  })
}

// 修改仓库
export function editRow(data) {
  return request({
    url: '/baseAdmin/depot-info/update',
    method: 'post',
    data,
  })
}

// 仓库排序
export function sort(data) {
  return request({
    url: '/baseAdmin/depot-info/order',
    method: 'post',
    data,
  })
}
// 下拉

export function getList(data) {
  return request({
    url: '/baseAdmin/depot-info/get-driver',
    method: 'post',
    data,
  })
}

// 创建

export function create(data) {
  return request({
    url: '/baseAdmin/depot-info/create',
    method: 'post',
    data,
  })
}
// 改变负库存下单状态
export function toggleFKC(data) {
  return request({
    url: '/baseAdmin/depot-info/minus-status',
    method: 'post',
    data,
  })
}

// 仓库关联人权限列表
export function permissionsList(data) {
  return request({
    url: '/depotAdmin/depot-rel/index',
    method: 'post',
    data,
  })
}

// 仓库关联人权限提交
export function subPermissions(data) {
  return request({
    url: '/depotAdmin/depot-rel/submit',
    method: 'post',
    data,
  })
}
/**
 * 仓库直销api
 */

// 拥有出库权限的出货仓库列表
export function depotList(data) {
  return request({
    url: '/depotAdmin/order/depot',
    method: 'post',
    data,
  })
}
// 仓库直销列表
export function depotSale(data) {
  return request({
    url: '/depotAdmin/order/index',
    method: 'post',
    data,
  })
}

// 仓库直销详情
export function depotDetail(data) {
  return request({
    url: '/depotAdmin/order/view',
    method: 'post',
    data,
  })
}

// 订单废弃
export function deleteDepot(data) {
  return request({
    url: '/depotAdmin/order/invalid',
    method: 'post',
    data,
  })
}

// 后台仓库直销下单
export function saveDepotSale(data) {
  return request({
    url: '/depotAdmin/order/do-bill-order',
    method: 'post',
    data,
  })
}
