var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加兑奖单",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "orderTest-container",
          staticStyle: { "min-height": "600px" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "购货方:", prop: "cust_name" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    staticStyle: { width: "200px" },
                    attrs: { "popper-class": "selectDC" },
                    on: { "select-val-alldata": _vm.clientSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "boss", $$v)
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "出货仓库", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "选择出货仓库",
                      },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.depotList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "选择业务员",
                      },
                      model: {
                        value: _vm.form.staff_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "staff_id", $$v)
                        },
                        expression: "form.staff_id",
                      },
                    },
                    _vm._l(_vm.staffList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "添加兑奖介质", prop: "" } },
                _vm._l(_vm.btnList, function (btn, index) {
                  return _c(
                    "el-button",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.handleBtnClick(btn)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(btn.goods_name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.tableList, function (table, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                key: index,
                staticStyle: { "margin-top": "10px" },
              },
              [
                _c(
                  "el-card",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.hanleAddJiangpin(index)
                          },
                        },
                      },
                      [_vm._v(" 添加奖品 ")]
                    ),
                    _c(
                      "el-table",
                      { attrs: { data: table.goods_jiezhi } },
                      [
                        _vm._l(_vm.columns, function (item, tableIndex) {
                          return _c("el-table-column", {
                            key: tableIndex,
                            attrs: {
                              prop: item.prop,
                              label: item.label,
                              width: item.width,
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      item.prop == "duijiang"
                                        ? _c("div", [_vm._v("回收")])
                                        : item.prop == "unit"
                                        ? _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row.goods_unit) + " "
                                            ),
                                          ])
                                        : item.prop == "goods_num"
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-input-number", {
                                                attrs: { type: "number" },
                                                model: {
                                                  value: row.goods_num,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "goods_num",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "row.goods_num",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row[item.prop]) + " "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _c(
                          "el-table-column",
                          { attrs: { label: "操作", align: "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteHuishou(index)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          "show-header": false,
                          data: table.goods_prize,
                        },
                      },
                      [
                        _vm._l(_vm.columns, function (item, tableIndex) {
                          return _c("el-table-column", {
                            key: tableIndex,
                            attrs: {
                              prop: item.prop,
                              label: item.label,
                              width: item.width,
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      item.prop == "duijiang"
                                        ? _c("div", [_vm._v("奖品")])
                                        : item.prop == "goods_name"
                                        ? _c(
                                            "div",
                                            [
                                              _c("goods-search", {
                                                attrs: {
                                                  "f-key": row.goods_name,
                                                },
                                                on: {
                                                  "add-rows": function (
                                                    $event
                                                  ) {
                                                    return _vm.addRows(
                                                      $event,
                                                      row,
                                                      index,
                                                      $index
                                                    )
                                                  },
                                                  "select-goods-all": function (
                                                    $event
                                                  ) {
                                                    return _vm.selectGoods(
                                                      $event,
                                                      row,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : item.prop == "unit"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "140px",
                                                  },
                                                  attrs: {
                                                    clearable: "",
                                                    placeholder: "单位",
                                                  },
                                                  model: {
                                                    value: row.unit_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "unit_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.unit_id",
                                                  },
                                                },
                                                _vm._l(
                                                  row.unit_arr,
                                                  function (i, idx) {
                                                    return _c("el-option", {
                                                      key: idx,
                                                      attrs: {
                                                        value: i.id,
                                                        label: i.unit_name,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.prop == "goods_num"
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-input-number", {
                                                attrs: { type: "number" },
                                                model: {
                                                  value: row.goods_num,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "goods_num",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "row.goods_num",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              " " + _vm._s(row[item.prop]) + " "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeleteJiangpin(
                                              $index,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "margin-top": "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              width: "49%",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v("奖现金:")]
                            ),
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              model: {
                                value: table.cash_info.give_money,
                                callback: function ($$v) {
                                  _vm.$set(table.cash_info, "give_money", $$v)
                                },
                                expression: "table.cash_info.give_money",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              width: "49%",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v("奖值合计:")]
                            ),
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { disabled: "" },
                              model: {
                                value: table.cash_info.goods_amount,
                                callback: function ($$v) {
                                  _vm.$set(table.cash_info, "goods_amount", $$v)
                                },
                                expression: "table.cash_info.goods_amount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("span", { staticStyle: { "margin-right": "5px" } }, [
                _vm._v("备注:"),
              ]),
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { type: "textarea" },
                model: {
                  value: _vm.remark,
                  callback: function ($$v) {
                    _vm.remark = $$v
                  },
                  expression: "remark",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "dialog-bottom" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }